<template>
    <div class="card">
        <div
            v-for="(option, i) in options"
            :key="option.value"
            class="d-flex cursor-pointer align-items-center justify-content-between p-3"
            :class="{'border-top': i > 0}"
            @click="changeValue(option.value)"
        >
            <div>
                <div :class="`text-${option.color}`">
                    {{ option.label }}
                </div>

                <small> {{ option.description }}</small>
            </div>

            <ow-checkbox
                class="flex-shrink-0 ml-3"
                :value="value === option.value"
                @input="changeValue(option.value)"
            />
        </div>
    </div>
</template>

<script>
import OwCheckbox from '@/components/common/OwCheckbox';
import PostPrivacy from '@/library/enumerations/PostPrivacy';

/**
 * A radio group component to choose a post privacy.
 */
export default {
    name: 'RadioGroupPostPrivacy',
    components: {OwCheckbox},
    props: {
        value: {
            type: Number,
            default: PostPrivacy.PUBLIC,
        },
    },
    data: () => ({
        /**
         * An array of PostPrivacy options as that can be chosen.
         */
        options: PostPrivacy.keys.map(key => ({
            label: PostPrivacy.translateKey(key, 'text'),
            description: PostPrivacy.translateKey(key, 'description'),
            value: PostPrivacy.value(key),
            color: PostPrivacy.getColor(key),
        })),
    }),
    methods: {
        changeValue(newValue) {
            if (newValue != this.value) {
                this.$emit('input', newValue);
            }
        },
    },
};
</script>

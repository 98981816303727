<template>
    <div
        class="selection d-flex"
        :class="{selected: value}"
        @click="toggleSelection"
    />
</template>

<script>
/**
 * A customized checkbox that looks like a radio button.
 */
export default {
    name: 'OwCheckbox',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        /**
         * Updates the selection state.
         */
        toggleSelection() {
            this.$emit('input', !this.value);
        },
    },
};
</script>

<style lang="scss">
$selection-size: 18px;
$selection-inner-gap: 1px;
$selection-border-width: 1px;
$selection-inner-size: $selection-size - ($selection-inner-gap * 2) - ($selection-border-width * 2);

.selection {
    width: $selection-size;
    height: $selection-size;
    cursor: pointer;
    border: $selection-border-width solid $primary;
    border-radius: 50%;
    &.selected::after {
        display: flex;
        align-self: center;
        width: $selection-inner-size;
        height: $selection-inner-size;
        margin: 0 auto;
        content: '';
        background: $primary;
        border-radius: 50%;
    }
}
</style>

<template>
    <!-- If there is more than one included language, display a tabbed version -->
    <b-tabs
        v-if="includedLanguages.length > 1"
        content-class="mt-3"
        fill
    >
        <b-tab
            v-for="language in includedLanguages"
            :key="language.locale"
            :title="language.label"
            :active="language.locale === active"
        >
            <slot :locale="language.locale" />
        </b-tab>
    </b-tabs>

    <!-- If there is only one language, display it in a div -->
    <div v-else-if="includedLanguages.length === 1">
        <slot :locale="includedLanguages[0].locale" />
    </div>

    <!-- If there is only one language, display it in a div but use the default locale -->
    <div v-else>
        <slot :locale="defaultLocale" />
    </div>
</template>

<script>
import SupportedLanguages from '@/library/SupportedLanguages';

/**
 * Tabbed component that allows certain sections of a form to be editable in multiple languages,
 * the component will provide a slot for each included locale, which can be accessed via `locale` in the slot scope.
 */
export default {
    name: 'MultiLanguageTabs',
    props: {
        /**
        * The locales that should be included in the tabs.
        * When undefined, all defined locales will be included.
        * When only one locale is included, the component will not display the tabs and uses a simple div instead.
        * When no locale is included, the component will use a div and provides the default locale "nl" to the slot.
        */
        includeLocales: {
            type: Array,
            required: false,
            default: undefined,
        },
    },
    data: function() {
        const included = this.filterLanguages(this.includeLocales, SupportedLanguages);
        const active = included.length >= 1 ? included[0].locale : undefined;
        return {
            languages: SupportedLanguages,
            active,
            defaultLocale: 'nl',
        };
    },
    computed: {
        includedLanguages() {
            return this.filterLanguages(this.includeLocales, this.languages);
        },
    },
    methods: {
        filterLanguages(includedLocales, languages) {
            if (Array.isArray(includedLocales)) {
                return languages.filter(language => includedLocales.includes(language.locale));
            }

            return languages;
        },
    },
};
</script>
